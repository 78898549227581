import React, { ChangeEvent, FunctionComponent, Ref, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import {
  ExtendedFile,
  HTMLFileInputElement,
  MessageType,
} from '@savgroup-front-common/types';

import {
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '../../../formatters';
import { useCombinedRefs } from '../../../hooks';
import { AttachmentIcon, PlusIcon } from '../../../protons/icons';
import { QrCodeIcon } from '../../../protons/icons/QrCode.icon';
import { Button, ButtonGroup } from '../../button';
import { FieldMessage } from '../common';
import { getFinalFieldState } from '../common/helpers/getFinalFieldState';
import { FieldMessages } from '../common/helpers/getFinalFieldState.types';
import { Label } from '../common/Label/Label';

import AttachmentsList from './AttachmentsList';
import messages from './messages';
import {
  $Col,
  $Dropzone,
  $FileInput,
  $IconContainer,
} from './MultiFileUploadHookForm.styles';

interface MultiFileUploadHookFormProps {
  label?: MessageType;
  dropZoneLabel?: MessageType;
  postLabel?: any;
  isRequired?: boolean;
  dropzone?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  isSuccess?: boolean;
  disabled?: boolean;
  isDisabled?: boolean;
  forwardedRef?: Ref<HTMLInputElement> | (() => void);
  allowedMimeTypes?: string[];
  errors: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isLoading?: boolean;
  files?: ExtendedFile[];
  hollow?: boolean;
  onRemove?: (attachment: ExtendedFile, index: number) => void;
  name: string;
  dataTestId?: string;
  onSelect: (files?: File[]) => void;
  isLiveUpload?: boolean;
  isFullWidth?: boolean;
  onImportByQrCodeClick?: () => void;
}
interface MultiFileUploadHookFormRef extends MultiFileUploadHookFormProps {
  forwardedRef?: Ref<HTMLInputElement> | (() => void);
}

const MultiFileUploadHookForm: FunctionComponent<
  React.PropsWithChildren<MultiFileUploadHookFormRef>
> = ({
  label,
  postLabel = null,
  dropzone = false,
  name,
  isRequired = false,
  isError = false,
  isWarning = false,
  isSuccess = false,
  disabled = false,
  isDisabled = false,
  allowedMimeTypes = [],
  files = [],
  forwardedRef = null,
  errors = {},
  warnings = {},
  successes = {},
  onSelect,
  onRemove = undefined,
  isLoading = false,
  hollow = false,
  isLiveUpload = true,
  dropZoneLabel,
  dataTestId,
  isFullWidth,
  onImportByQrCodeClick,
}) => {
  const transitionDisabled = disabled || isDisabled;
  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  const fileRef = useRef<HTMLInputElement>(null);
  const combinedRefs = useCombinedRefs(fileRef, forwardedRef);

  const handleClickInput = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (fileRef.current) {
      fileRef.current.click();
    }
    event.preventDefault();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (docs) => onSelect(docs),
  });

  return (
    <>
      {label && (
        <Label
          isRequired={isRequired}
          htmlFor={name}
          postLabel={postLabel}
          status={status}
        >
          {SafeFormattedMessage(label)}
        </Label>
      )}
      <$FileInput
        {...getInputProps()}
        id={name}
        name={name}
        ref={combinedRefs}
        disabled={transitionDisabled}
        type="file"
        accept={allowedMimeTypes.join(',')}
        onChange={(e: ChangeEvent<HTMLFileInputElement>) => {
          onSelect(e.target.files ? Array.from(e.target.files) : undefined);
          e.target.value = '';
        }}
        data-testid={dataTestId}
      />
      <Row between="sm">
        <$Col $dropzone={dropzone} xs={12} sm={isFullWidth ? 12 : 6}>
          {dropzone && (
            <$IconContainer $isDragActive={isDragActive}>
              <AttachmentIcon />
              <$Dropzone
                {...(getRootProps() as any)}
                onClick={handleClickInput}
                type={BUTTON_TYPES.BUTTON}
                $isDragActive={isDragActive}
              >
                <p>
                  <SafeFormattedMessageWithoutSpread
                    message={dropZoneLabel || messages.dragAndDropFiles}
                  />
                </p>
              </$Dropzone>
            </$IconContainer>
          )}
          {!dropzone && (
            <>
              <ButtonGroup withMargin={false}>
                <Button
                  disabled={transitionDisabled}
                  primary
                  hollow={hollow}
                  onClick={handleClickInput}
                  danger={isError}
                  type={BUTTON_TYPES.BUTTON}
                  icon={<PlusIcon />}
                  isLoading={isLoading}
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.fileInput}
                  />
                </Button>

                {onImportByQrCodeClick && (
                  <Button
                    disabled={transitionDisabled}
                    primary
                    onClick={onImportByQrCodeClick}
                    type={BUTTON_TYPES.BUTTON}
                    icon={<QrCodeIcon />}
                    isLoading={isLoading}
                    dataTestId={`qrCodeButton_${dataTestId}`}
                  />
                )}
              </ButtonGroup>

              <FieldMessage
                message={message}
                status={status}
                dataTestId={dataTestId}
              />
            </>
          )}
        </$Col>

        {files.length > 0 && (
          <Col style={{ flex: 1 }} xs={12} sm={isFullWidth ? 12 : 6}>
            <AttachmentsList
              name={name}
              files={files}
              onRemove={onRemove}
              isLiveUpload={isLiveUpload}
              errors={errors}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

MultiFileUploadHookForm.displayName = 'MultiFileUploadHookForm';

export default React.forwardRef<HTMLInputElement, MultiFileUploadHookFormProps>(
  (props, ref) => <MultiFileUploadHookForm forwardedRef={ref} {...props} />,
);
