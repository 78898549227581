import { rgba, setLightness } from 'polished';
import styled, { css } from 'styled-components';

import { Z_INDEX_VERTICAL_MENU } from '@savgroup-front-common/constants/src/shared';
import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

import { transitionRightOrLeft } from '../../animations/complexeStateAnimation';
import {
  simpleSlideFromBottomAnimation,
  simpleSlideFromTopAnimation,
} from '../../animations/simpleAnimation';
import { StateAnimationProps } from '../../animations/stateAnimation';
import { rem, styledGetCustomComponentProperty } from '../../helpers';
import { Link } from '../link';

import { MENU_POSITIONS, MENU_VARIANTS } from './Menu.types';

const disabledCss = css`
  color: ${({ theme }) => theme.colors.paragraphTextColor}!important;
  background-color: transparent !important;
  cursor: not-allowed;
`;

interface MenuItemMixinProps {
  $color?: string;
  disabled?: boolean;
  $isDisabled?: boolean;
  $variant: MENU_VARIANTS;
}

export const $MenuContent = styled.span`
  display: flex;
  align-items: center;
`;

export const $MenuTextContainer = styled.div`
  display: flex;

  flex-direction: column;
  *:nth-child(1) {
  }
  *:nth-child(2) {
    font-weight: ${({ theme }) => theme.fonts.weight.normal};
    font-size: ${({ theme }) => theme.fonts.size.mini};
    color: ${({ theme }) => theme.colors.default};
  }
`;

const menuItemMixinNewUI = css<MenuItemMixinProps>`
  padding: 6px 12px 6px 8px;

  color: ${({ theme, $color }) => {
    if (!$color) {
      return theme.colors.mainTextColor;
    }

    return $color;
  }};

  height: unset;

  svg {
    color: ${({ theme, disabled, $isDisabled, $color }) => {
      if (disabled || $isDisabled) {
        return theme.colors.paragraphTextColor;
      }

      return $color ?? theme.newUI?.defaultColors?.secondary;
    }}!important;
  }

  &:focus,
  &:hover {
    color: ${({ theme, $color }) => {
      if (!$color) {
        return theme.colors.mainTextColor;
      }

      return setLightness(0.75, $color);
    }};
  }
`;

const menuItemMixin = css<MenuItemMixinProps>`
  padding: 0 1rem;
  text-align: left;
  color: ${({ theme, $color }) => {
    if (!$color) {
      return theme.colors.white;
    }

    return setLightness(0.75, $color);
  }};
  background-color: transparent;
  width: 100%;
  height: ${rem(38)};
  font-size: ${({ theme }) => theme.fonts.size.small};
  border: 0;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  outline: 0;
  white-space: nowrap;
  min-width: 14rem;
  cursor: pointer;

  svg {
    width: ${rem(20)};
    height: ${rem(20)};
    margin-right: ${rem(10)};
    color: ${({ theme, $color, disabled, $isDisabled }) => {
      if (disabled || $isDisabled) {
        return theme.colors.paragraphTextColor;
      }

      return $color ? setLightness(0.75, $color) : theme.colors.white;
    }}!important;
  }

  &:focus,
  &:hover {
    color: ${({ theme, $color }) => {
      if (!$color) {
        return theme.colors.white;
      }

      return setLightness(0.75, $color);
    }};
    text-decoration: none;
    background-color: ${({ theme, $color }) =>
      rgba(setLightness(0.7, $color || theme.colors.darkBackground), 0.3)};
  }
  &:active {
    background-color: ${({ theme, $color }) =>
      rgba(setLightness(0.7, $color || theme.colors.darkBackground), 0.5)};
  }
  ${({ disabled, $isDisabled }) => (disabled || $isDisabled) && disabledCss}

  ${({ $variant }) =>
    $variant === MENU_VARIANTS.NEW_UI ? menuItemMixinNewUI : null}
`;

export const $MenuButton = styled.button<MenuItemMixinProps>`
  ${menuItemMixin}
`;
export const $MenuBack = styled.button<MenuItemMixinProps>`
  ${menuItemMixin};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  border-bottom: ${rem(1)} solid #666;
`;

export const $MenuSubMenu = styled.button<MenuItemMixinProps>`
  ${menuItemMixin};
  justify-content: space-between;
  svg:last-child {
    margin-right: -${rem(10)};
  }
`;

export const $MenuLink = styled(Link)<
  React.PropsWithChildren<MenuItemMixinProps>
>`
  ${menuItemMixin}
`;

const exiting = css`
  pointer-events: none;
`;

const entering = css`
  z-index: 1;
`;

interface $MenuTransitionProps extends StateAnimationProps {
  $position?: MENU_POSITIONS;
  $componentThemeName?: string;
  $variant: MENU_VARIANTS;
  $isNewBoEnabled?: boolean;
}

export const $MenuTransitionNewUI = css<$MenuTransitionProps>`
  background: ${({ $componentThemeName, theme }) => {
    if (!$componentThemeName) {
      return theme.colors.white;
    }

    return styledGetCustomComponentProperty({
      componentName: $componentThemeName,
      property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
    });
  }};
  color: ${({ $componentThemeName, theme }) => {
    if (!$componentThemeName) {
      return theme.colors.mainTextColor;
    }

    return styledGetCustomComponentProperty({
      componentName: $componentThemeName,
      property: SUPPORTED_PROPERTIES.COLOR,
    });
  }};
  padding: ${({ $componentThemeName }) => {
    if (!$componentThemeName) {
      return '.5rem';
    }

    return styledGetCustomComponentProperty({
      componentName: $componentThemeName,
      property: SUPPORTED_PROPERTIES.PADDING,
    });
  }};

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
`;

export const $MenuTransition = styled.ul<$MenuTransitionProps>`
  ${transitionRightOrLeft({ exiting, entering })};
  position: absolute;
  list-style: none;
  border-radius: ${({ $componentThemeName, theme, $isNewBoEnabled }) => {
    if ($componentThemeName) {
      return styledGetCustomComponentProperty({
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
      });
    }

    if ($isNewBoEnabled) {
      return theme.newUI.borders.bigRadius;
    }

    return theme.borders.radius;
  }};

  background: ${({ $componentThemeName, theme, $isNewBoEnabled }) => {
    if ($componentThemeName) {
      return styledGetCustomComponentProperty({
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
      });
    }

    if ($isNewBoEnabled) {
      return theme.colors.white;
    }

    return theme.colors.darkBackground;
  }};

  color: ${({ $componentThemeName }) => {
    if ($componentThemeName) {
      return styledGetCustomComponentProperty({
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.COLOR,
      });
    }

    return undefined;
  }};

  margin-top: ${rem(2)};
  display: flex;
  flex-direction: column;
  padding: ${({ $componentThemeName }) =>
    $componentThemeName
      ? styledGetCustomComponentProperty({
          componentName: $componentThemeName,
          property: SUPPORTED_PROPERTIES.PADDING,
        })
      : '0.25rem 0 0.25rem'};
  min-height: ${rem(40)};
  pointer-events: auto;

  border: ${({ $componentThemeName }) =>
    $componentThemeName
      ? styledGetCustomComponentProperty({
          componentName: $componentThemeName,
          property: SUPPORTED_PROPERTIES.BORDER,
        })
      : null};

  gap: 6px;
  ${({ $position }) => {
    switch ($position) {
      case MENU_POSITIONS.TOP:
        return css`
          top: 0;
        `;
      case MENU_POSITIONS.BOTTOM:
        return css`
          top: 200%;
        `;
      case MENU_POSITIONS.LEFT:
        return css`
          left: 0;
        `;
      case MENU_POSITIONS.RIGHT:
        return css`
          right: 0;
        `;

      default: {
        return null;
      }
    }
  }};

  ${({ $variant }) =>
    $variant === MENU_VARIANTS.NEW_UI ? $MenuTransitionNewUI : null}
`;

export const $MenuTitleNewUI = css<{ $variant: MENU_VARIANTS }>`
  padding: 0 12px 0 8px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.newUI?.defaultColors?.text};
`;

export const $MenuTitle = styled.h6<{
  $variant: MENU_VARIANTS;
}>`
  padding: 0 1rem;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  line-height: 1;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};

  ${({ $variant }) =>
    $variant === MENU_VARIANTS.NEW_UI ? $MenuTitleNewUI : null}
`;

export const $MenuItemStyled = styled.li`
  width: 100%;
  ${simpleSlideFromBottomAnimation};
`;

export const $MenuStyled = styled.ul`
  list-style: none;
  ${simpleSlideFromTopAnimation};
  border-radius: ${({ theme }) => theme.borders.radius};
  background: ${({ theme }) => theme.colors.darkBackground};
  margin-top: ${rem(2)};
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0 0.25rem;
  min-height: ${rem(40)};
`;

interface $MenuContainerProps {
  $position?: MENU_POSITIONS;
  animationDuration?: number;
}

export const $MenuContainer = styled.div<$MenuContainerProps>`
  ${simpleSlideFromTopAnimation};
  pointer-events: none;
  position: relative;
  z-index: ${Z_INDEX_VERTICAL_MENU};
  ${({ $position }) => {
    switch ($position) {
      case MENU_POSITIONS.BOTTOM:
        return css`
          right: 100%;
        `;

      default: {
        return null;
      }
    }
  }};
`;
