import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { FLEX_ALIGN } from '@savgroup-front-common/types';

import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';
import { rem } from '../../helpers';

const titleMixin = css<{ $color?: string; $isNewBoEnabled?: boolean }>`
  color: ${({ theme, $color, $isNewBoEnabled }) =>
    $isNewBoEnabled
      ? $color || theme.newUI.defaultColors.text
      : $color || theme.colors.headingTextColor};
  font-weight: ${({ theme, $isNewBoEnabled }) =>
    $isNewBoEnabled ? theme.fonts.weight.bold : theme.fonts.weight.normal};

  display: flex;
  align-items: center;
  margin-bottom: 0;

  svg {
    margin-right: 0.5rem;
    color: ${({ theme, $color }) => $color || theme.colors.headingTextColor};
  }
`;

const sizeMixin = (size: string) => css`
  font-size: ${size};
  svg {
    width: ${size};
    height: ${size};
  }
`;

export const $H1 = styled.h1`
  ${titleMixin};
  ${({ theme }) => sizeMixin(theme.fonts.size.heading.h1)};
`;

export const $H2 = styled.h2`
  ${titleMixin};
  ${({ theme }) => sizeMixin(theme.fonts.size.heading.h2)};
`;

export const $H3 = styled.h3`
  ${titleMixin};
  ${({ theme }) => sizeMixin(theme.fonts.size.heading.h3)};
`;

export const $H4 = styled.h4`
  ${titleMixin};
  ${({ theme }) => sizeMixin(theme.fonts.size.heading.h4)};
`;

export const $H5 = styled.h5`
  ${titleMixin};
  ${({ theme }) => sizeMixin(theme.fonts.size.heading.h5)};
`;

export const $H6 = styled.h6`
  ${titleMixin};
  ${({ theme }) => sizeMixin(theme.fonts.size.heading.h6)};
`;

const transitionMixin = css`
  transition-duration: 300ms;
  ${decelerateTimingFunctionTransition};
`;

const copyMixin = css`
  position: relative;
  border-radius: ${({ theme }) => theme.borders.radius};

  background: ${rgba('#ddd', 0)};
  > svg {
    opacity: 0;
    margin-left: ${rem(8)};
    ${transitionMixin};
    transition-duration: 1000ms;
    min-width: ${rem(12)};
    transform: scale(1);
  }

  &:hover {
    > svg {
      opacity: 1;
    }

    background: ${rgba('#ddd', 1)};
  }
  &:active {
    background: ${rgba('#ccc', 1)};
    user-select: none;
    > svg {
      transition-duration: 100ms;
      transform: scale(1.3);
    }
  }
`;

export const $HeadingContainer = styled.div<{
  $align: FLEX_ALIGN;
  $withoutMargin: boolean;
}>`
  display: flex;
  justify-content: ${({ $align }) => $align};
  &:not(:last-child) {
    margin-bottom: ${({ $withoutMargin }) => ($withoutMargin ? null : '1rem')};
  }
`;
export const $CopyStyle = styled.span<{ $hasCopy: boolean }>`
  white-space: pre-line;
  word-break: break-word;
  display: flex;
  align-items: center;

  width: fit-content;
  ${transitionMixin};
  padding: ${rem(3)};
  transform: translateX(-3px);
  ${({ $hasCopy }) => ($hasCopy ? copyMixin : null)};
`;
