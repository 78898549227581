import styled, { css } from 'styled-components';

import { decelerateTimingFunctionTransition } from '../../../animations/timingFunction';
import { rem } from '../../../helpers';
import { Link } from '../../link';
import { getColor } from '../Button.helpers';
import { buttonStyleMixin } from '../Button.styles';

import {
  getHeight,
  getOnHoverColor,
  handleJustifyContent,
  handleMargin,
} from './ButtonLink.helpers';

const handleHoverAsHollow = (props: any) => {
  const { disabled, theme } = props;
  const { interactions = {} } = theme;

  if (!interactions.buttonHoverAsHollow && disabled) {
    return '';
  }

  return css`
    &:hover,
    &:focus {
      color: ${getOnHoverColor};
      svg {
        color: ${getOnHoverColor(props)};
      }
    }
  `;
};

interface HandleUnderlineArgs {
  underline?: boolean;
}

const handleUnderline = ({ underline }: HandleUnderlineArgs) => {
  if (!underline) {
    return null;
  }

  return css`
    display: flex;
  `;
};

const innerLinkOverload = (props: any) => {
  const { $isNewBoEnabled } = props;

  if ($isNewBoEnabled) {
    return null;
  }

  return css`
    width: ${({ isFluid }: any) => (isFluid ? null : 'fit-content')};

    font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};

    svg {
      flex-shrink: 0;
      color: ${(props: any) => props.$iconColor || getColor(props)};
      max-height: ${rem(20)};
      max-width: ${rem(20)};
      transition-property: fill;
      transition-duration: 0.3s;
      ${decelerateTimingFunctionTransition};
    }
    i.icon {
      height: ${rem(20)};
    }
    span {
      width: max-content;
    }
    > span {
      width: 100%;
    }

    ${getHeight};
    ${handleJustifyContent};
    ${handleMargin};

    ${handleUnderline};
    ${handleHoverAsHollow};
  `;
};

export const $InnerLinkStyled = styled<any>(Link)`
  ${buttonStyleMixin};
  ${innerLinkOverload};
  &,
  &:hover {
    text-decoration: none;
  }
`;
