import { useLocation } from 'react-use';

import { FEATURE_FLAGS, ROLES } from '@savgroup-front-common/constants';

import { useHasRole } from '../../../hooks/useHasRole';

import useIsFeatureEnabled from './useIsFeatureEnabled';

const useIsNewBoEnabled = (): boolean => {
  const isNewBoEnabled = useIsFeatureEnabled(FEATURE_FLAGS.BO_NEW_UI);
  const isDemoAdmin = useHasRole(ROLES.DEMO_ADMINISTRATOR);

  const { pathname } = useLocation();

  return (
    (isNewBoEnabled || isDemoAdmin || pathname?.includes('new_design')) ?? false
  );
};

export default useIsNewBoEnabled;
