import { darken, lighten, transparentize } from 'polished';
import { css, DefaultTheme } from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types/src/Theme';

import { getCustomComponentProperty } from '../../helpers/theme';
import getTextColorBasedOnProps from '../../theme/helpers/getTextColorBasedOnProps';

import { InnerButtonStyledProps } from './Button.types';

interface ColorBasedOnProps {
  theme: DefaultTheme;
  componentThemeName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  hollow?: boolean;
  underline?: boolean;
}

export const colorBasedOnProps = (
  props: ColorBasedOnProps,
  defaultValue?: string | false | null,
) => {
  const { theme, componentThemeName, disabled, isLoading, hollow, underline } =
    props;

  const themeValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property:
      disabled && !isLoading
        ? SUPPORTED_PROPERTIES.DISABLED_COLOR
        : SUPPORTED_PROPERTIES.COLOR,
  });

  if (componentThemeName && themeValue && !hollow && !underline) {
    return themeValue;
  }

  return defaultValue || getTextColorBasedOnProps(props);
};

export const getColor = (props: InnerButtonStyledProps) => {
  const {
    theme,
    componentThemeName,
    hollow,
    disabled,
    underline,
    secondary,
    isLoading,
    naked,
    $isPressed,
    tertiary,
    $isNewBoEnabled,
  } = props;

  const hasWhiteBackground = secondary || tertiary || naked;

  let defaultValue = hasWhiteBackground ? undefined : theme.colors.white;

  const themeValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.COLOR,
  });

  if (disabled && !isLoading && $isNewBoEnabled) {
    return theme.newUI.variationColors?.light2;
  }

  if (hollow && !disabled) {
    defaultValue = undefined;
  }

  if (underline) {
    return colorBasedOnProps(props);
  }

  if (disabled && !isLoading && !naked) {
    return getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.DISABLED_COLOR,
      fallback: defaultValue,
    });
  }

  if (secondary && $isPressed && $isNewBoEnabled) {
    return theme.newUI.defaultColors.text;
  }

  if (secondary && $isPressed) {
    return theme.colors.white;
  }

  if (tertiary && ($isPressed || isLoading)) {
    return theme.colors.mainTextColor;
  }

  return themeValue || colorBasedOnProps(props, defaultValue);
};

export const getIconColor = (props: InnerButtonStyledProps) => {
  const {
    theme,
    componentThemeName,
    hollow,
    disabled,
    underline,
    secondary,
    isLoading,
    naked,
    $isPressed,
    tertiary,
    alert,
    danger,
    $isNewBoEnabled,
  } = props;

  const hasWhiteBackground = secondary || tertiary || naked || hollow;

  let defaultValue = hasWhiteBackground ? undefined : theme.colors.white;

  const themeValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.COLOR,
    fallback: colorBasedOnProps(props, defaultValue),
  });

  if (disabled && !isLoading && $isNewBoEnabled) {
    return theme.newUI.variationColors?.light2;
  }

  if (hollow && !disabled) {
    defaultValue = undefined;
  }

  if (underline) {
    return colorBasedOnProps(props);
  }

  if (disabled && !isLoading && !naked) {
    return getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.DISABLED_COLOR,
      fallback: defaultValue,
    });
  }

  if (alert && $isNewBoEnabled) {
    return theme.newUI.iconColors.alert.color;
  }

  if (danger && $isNewBoEnabled && secondary) {
    return theme.newUI.iconColors.alert.color;
  }

  if (secondary && $isNewBoEnabled) {
    return theme.newUI.iconColors.primary.color;
  }

  if (secondary && $isNewBoEnabled && $isPressed) {
    return theme.newUI.iconColors.primary.color;
  }

  if (secondary && $isPressed) {
    return theme.colors.white;
  }

  if (tertiary) {
    return theme.colors.tertiary;
  }

  return themeValue;
};

export const getButtonPadding = (props: InnerButtonStyledProps) => {
  const { theme, componentThemeName, small, $isIcon, $isMinimal } = props;

  const smallButtonFallback = getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS,
    property: SUPPORTED_PROPERTIES.PADDING,
  });

  if ($isMinimal) {
    return '0';
  }

  if (small) {
    return getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.PADDING,
      fallback: smallButtonFallback,
    });
  }

  if ($isIcon) {
    return '17px 23px 17px 15px';
  }

  return '17px 19px';
};

export const getFontSize = (props: InnerButtonStyledProps) => {
  const { theme, small } = props;

  if (small) {
    return getCustomComponentProperty({
      theme,
      componentName: SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS,
      property: SUPPORTED_PROPERTIES.FONT_SIZE,
      fallback: theme.fonts.size.ultraSmall,
    });
  }

  return getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.DEFAULT_BUTTONS,
    property: SUPPORTED_PROPERTIES.FONT_SIZE,
    fallback: theme.fonts.size.small,
  });
};

export const getFontWeight = (props: InnerButtonStyledProps) => {
  const { theme, small } = props;

  if (small) {
    return getCustomComponentProperty({
      theme,
      componentName: SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS,
      property: SUPPORTED_PROPERTIES.FONT_WEIGHT,
    });
  }

  return getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.DEFAULT_BUTTONS,
    property: SUPPORTED_PROPERTIES.FONT_WEIGHT,
  });
};

export const getButtonsTextTransform = (props: InnerButtonStyledProps) => {
  const { theme, componentThemeName } = props;
  const customValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.TEXT_TRANSFORM,
  });

  if (customValue) {
    return customValue;
  }

  return getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.DEFAULT_BUTTONS,
    property: SUPPORTED_PROPERTIES.TEXT_TRANSFORM,
  });
};

export const getWidth = (props: InnerButtonStyledProps) => {
  const { theme, componentThemeName, fluid } = props;

  const customValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.WIDTH,
  });

  if (customValue) {
    return customValue;
  }

  return fluid ? '100%' : 'auto';
};

export const getHeight = (props: InnerButtonStyledProps) => {
  const { theme, componentThemeName, small } = props;

  const smallDefaultHeight = getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS,
    property: SUPPORTED_PROPERTIES.HEIGHT,
  });

  const defaultHeight = getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
    property: SUPPORTED_PROPERTIES.HEIGHT,
  });

  return getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.HEIGHT,
    fallback: small ? smallDefaultHeight : defaultHeight,
  });
};

export const getMinHeight = (props: InnerButtonStyledProps) => {
  const { theme, componentThemeName, small, $isMinimal } = props;

  const smallDefaultMinHeight = getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS,
    property: SUPPORTED_PROPERTIES.MIN_HEIGHT,
  });

  const defaultMinHeight = getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
    property: SUPPORTED_PROPERTIES.MIN_HEIGHT,
  });

  if ($isMinimal) {
    return undefined;
  }

  return getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.MIN_HEIGHT,
    fallback: small ? smallDefaultMinHeight : defaultMinHeight,
  });
};

export const getBackgroundColor = (
  props: InnerButtonStyledProps,
): string | undefined => {
  const {
    theme,
    secondary,
    tertiary,
    componentThemeName,
    disabled,
    hollow,
    underline,
    isLoading,
    $isPressed,
    $isNewBoEnabled,
  } = props;

  let result = '';

  if (disabled && !isLoading && $isNewBoEnabled) {
    return theme.newUI.variationColors?.light5;
  }

  if (disabled && !isLoading) {
    return getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.DISABLED_BACKGROUND_COLOR,
      fallback: colorBasedOnProps(props),
    });
  }

  const themeValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
  });

  if (hollow) {
    const backgroundColorDisabled = colorBasedOnProps(props);

    if (disabled && !isLoading) {
      return themeValue || backgroundColorDisabled;
    }

    return themeValue || 'transparent';
  }

  if (themeValue && !hollow && !underline) {
    result = themeValue;
  } else if (secondary || tertiary) {
    result = theme.colors.white;
  } else {
    result = getTextColorBasedOnProps(props);
  }

  if (isLoading) {
    return getBackgroundColor({ ...props, isLoading: false, disabled: false });
  }

  if ($isPressed && !$isNewBoEnabled) {
    if (secondary) {
      result = darken(0.15, theme.colors.primary);
    } else if (tertiary) {
      result = theme.colors.secondaryColor2;
    } else if ($isNewBoEnabled) {
      result =
        theme.newUI.variationColors?.dark1 || theme.newUI.defaultColors.primary;
    } else {
      result = lighten(0.45, result);
    }
  }

  return result;
};

export const getBorder = (
  props: InnerButtonStyledProps,
  defaultValue = '1px solid transparent',
) => {
  const {
    theme,
    componentThemeName,
    disabled,
    hollow,
    secondary,
    tertiary,
    isLoading,
    $isNewBoEnabled,
    $isPressed,
  } = props;
  let result = defaultValue;

  if (disabled && !isLoading && $isNewBoEnabled) {
    return `1px solid transparent`;
  }

  if (disabled && !isLoading) {
    return `1px solid ${theme.colors.white}`;
  }

  const customBorder = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.BORDER,
  });

  if (hollow) {
    if (isLoading) {
      const hollowBorderColor = colorBasedOnProps({
        ...props,
        isLoading: false,
        disabled: false,
      });

      result = customBorder || `1px solid ${hollowBorderColor}`;
    } else {
      const hollowBorderColor = colorBasedOnProps(props);

      result = customBorder || `1px solid ${hollowBorderColor}`;
    }
  } else if (customBorder) {
    result = customBorder;
  }

  if (secondary) {
    result = `1px solid ${theme.colors.primary}`;
  }

  if (tertiary) {
    result = `1px solid ${theme.colors.default}`;
  }

  if (secondary && $isNewBoEnabled) {
    result = `1px solid ${theme.newUI.variationColors?.light4}`;
  }

  if (secondary && $isNewBoEnabled && $isPressed) {
    result = `1px solid ${theme.newUI.variationColors?.dark1}`;
  }

  return result;
};

export const getOnHoverBackgroundColor = (
  props: InnerButtonStyledProps,
): string | null => {
  const {
    theme,
    componentThemeName,
    disabled,
    primary,
    danger,
    hollow,
    secondary,
    isLoading,
    underline,
    alert,
    $isNewBoEnabled,
    $isMinimal,
  } = props;
  const { interactions = { buttonHoverAsHollow: false } } = theme;

  if ((disabled && !isLoading) || underline || $isMinimal) {
    return null;
  }

  const themeValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR,
  });

  if (interactions.buttonHoverAsHollow && !hollow) {
    const color = colorBasedOnProps(props);

    return themeValue || transparentize(0.7, color);
  }

  if (hollow) {
    const color = colorBasedOnProps(props);
    const backgroundColorOnHover = transparentize(0.9, color);

    return themeValue || backgroundColorOnHover;
  }

  if (danger && $isNewBoEnabled) {
    return darken(0.3, theme.newUI.iconColors.alert.color);
  }

  if (alert && $isNewBoEnabled) {
    return theme.newUI.iconColors.alert.bgColor;
  }

  if (secondary && $isNewBoEnabled) {
    return transparentize(0.9, theme.newUI.defaultColors.primary);
  }

  if (themeValue) {
    return themeValue;
  }
  if (primary && theme.colors.primaryHover && !$isNewBoEnabled) {
    return theme.colors.primaryHover;
  }
  if (secondary) {
    return lighten(0.1, theme.colors.primary);
  }
  if (primary || danger) {
    return darken(0.15, getBackgroundColor(props) || '');
  }

  return darken(0.075, getBackgroundColor(props) || '');
};

export const getOnHoverColor = (props: InnerButtonStyledProps) => {
  const {
    theme,
    componentThemeName,
    hollow,
    disabled,
    underline,
    secondary,
    tertiary,
    naked,
    danger,
    $isNewBoEnabled,
  } = props;
  const { interactions = { buttonHoverAsHollow: false } } = theme;

  if (naked || ($isNewBoEnabled && disabled)) {
    return null;
  }

  let defaultValue = secondary || tertiary ? undefined : theme.colors.white;
  const themeValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.ON_HOVER_COLOR,
  });

  if (interactions.buttonHoverAsHollow) {
    return themeValue || null;
  }

  if (danger && secondary && $isNewBoEnabled) {
    return theme.newUI.iconColors.alert.color;
  }

  if (secondary && $isNewBoEnabled) {
    return theme.newUI.defaultColors.text;
  }

  if (themeValue) {
    return themeValue;
  }

  if (hollow && !disabled) {
    defaultValue = undefined;
  }
  if (underline) {
    return darken(0.3, colorBasedOnProps(props));
  }

  if (secondary) {
    return theme.colors.white;
  }

  return colorBasedOnProps(props, defaultValue);
};
export const getOnHoverBorder = (
  props: InnerButtonStyledProps,
  defaultValue?: string,
): string | undefined => {
  const {
    theme,
    componentThemeName,
    disabled,
    secondary,
    isLoading,
    danger,
    $isNewBoEnabled,
  } = props;

  let result = defaultValue || getBorder(props);
  const { interactions = { buttonHoverAsHollow: false } } = theme;

  if (disabled && !isLoading) {
    return undefined;
  }

  const themeValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.ON_HOVER_BORDER,
  });

  if (themeValue) {
    result = themeValue;
  }

  if (secondary && $isNewBoEnabled && danger) {
    return `1px solid ${theme.newUI.iconColors.alert.color}`;
  }

  if (secondary && $isNewBoEnabled) {
    return `1px solid ${theme.newUI.variationColors?.dark1}`;
  }

  if (secondary) {
    return `1px solid ${darken(0.15, theme.colors.white)}`;
  }

  if (interactions.buttonHoverAsHollow) {
    return themeValue;
  }

  return result;
};

export const getBorderRadius = (props: InnerButtonStyledProps) => {
  const { theme, componentThemeName, rounded, $isNewBoEnabled } = props;

  if (rounded) {
    return null;
  }

  const customBorderRadius = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
  });

  const defaultValue = getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.DEFAULT_BUTTONS,
    property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
  });

  if ($isNewBoEnabled) {
    return theme.newUI.borders.smallRadius;
  }

  return customBorderRadius || defaultValue;
};

export const underlineColorBasedOnProps = (props: InnerButtonStyledProps) => {
  if (props.disabled && !props.isLoading) {
    return null;
  }
  if (props.link) {
    return darken(0.1, getTextColorBasedOnProps(props));
  }

  return darken(0.1, colorBasedOnProps(props));
};

export const handleUnderlineProps = (props: InnerButtonStyledProps) => {
  if (!props.underline) {
    return '';
  }

  const color = colorBasedOnProps(props);
  const colorOnHover =
    props.disabled && !props.isLoading ? null : darken(0.1, color);
  const colorOnFocus =
    props.disabled && !props.isLoading ? null : darken(0.1, color);

  return css`
    color: ${color};
    padding: 0;
    text-decoration: underline;
    background-color: transparent;

    &:hover {
      color: ${colorOnHover};
    }
    &:focus {
      color: ${colorOnFocus};
    }
    ${props.link
      ? css`
          a {
            text-decoration: none !important;
            color: ${props.disabled && !props.isLoading
              ? darken(0.1, colorBasedOnProps(props))
              : getTextColorBasedOnProps(props)};
            cursor: ${props.disabled && !props.isLoading
              ? 'not-allowed'
              : 'pointer'};
            pointer-events: ${props.disabled && !props.isLoading
              ? 'none'
              : null};

            &:hover,
            &:focus {
              color: ${darken(0.1, getTextColorBasedOnProps(props))};
            }
          }
        `
      : null};
  `;
};

export const getWhiteSpace = (props: InnerButtonStyledProps) => {
  const { componentThemeName, theme } = props;

  const customValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.WHITE_SPACE,
  });

  return customValue || 'nowrap';
};
