import { tint } from 'polished';
import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

import {
  getCustomComponentProperty,
  rem,
  styledGetCustomComponentProperty,
} from '../../../helpers';
import { ACCORDION_ROLE } from '../Accordion.types';

interface $AccordionHeaderProps {
  $isErrored: boolean;
  $isActive: boolean;
  $isDisabled: boolean;
  $isList?: boolean;
  $role: ACCORDION_ROLE;
  $componentThemeName?: string;
  $isNewBoEnabled: boolean;
}

export const $AccordionHeader = styled.div<$AccordionHeaderProps>`
  width: 100%;

  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;

  background-color: ${({
    $role,
    theme,
    $isErrored,
    $isActive,
    $isDisabled,
    $componentThemeName,
    $isList,
    $isNewBoEnabled,
  }) => {
    if (
      ($isList && !$isNewBoEnabled) ||
      ($isList && $isNewBoEnabled && !$isActive)
    ) {
      return theme.colors.white;
    }

    if ($componentThemeName) {
      const getBackgroundColor = styledGetCustomComponentProperty({
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
      });
      const backgroundColor = getBackgroundColor({ theme });

      if (backgroundColor) {
        return backgroundColor;
      }
    }
    if ($isDisabled) {
      return $isActive ? theme.colors.default : theme.colors.backgroundColor;
    }
    if ($isErrored) {
      return $isActive ? theme.colors.danger : tint(0.85, theme.colors.danger);
    }

    switch ($role) {
      case ACCORDION_ROLE.PRIMARY:
        return theme.colors.headerTitleColor;
      case ACCORDION_ROLE.SECONDARY:
        return theme.colors.secondaryColor2;
      default:
        throw new Error('not supported role.');
    }
  }};
  border-radius: ${({ $isActive, $componentThemeName, theme }) =>
    $isActive
      ? `${
          getCustomComponentProperty({
            theme,
            componentName: $componentThemeName,
            property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
          }) || rem(5)
        } ${
          getCustomComponentProperty({
            theme,
            componentName: $componentThemeName,
            property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
          }) || rem(5)
        } 0 0`
      : styledGetCustomComponentProperty({
          componentName: $componentThemeName,
          property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
        }) || `${rem(5)}`};
  border: ${({ $isList, $isNewBoEnabled, theme }) => {
    if ($isNewBoEnabled) {
      return `1px solid ${theme.newUI.dividerColors.default}`;
    }

    if ($isList) {
      return 'none';
    }

    return '1px solid #9a30470d';
  }};
  height: ${({ $componentThemeName }) => {
    return styledGetCustomComponentProperty({
      property: SUPPORTED_PROPERTIES.HEIGHT,
      componentName: $componentThemeName,
      fallback: `${rem(50)}`,
    });
  }};
  padding: 0 1rem;
  cursor: ${({ $isDisabled }) => ($isDisabled ? undefined : 'pointer')};

  @media ${media.maxWidth.sm} {
    padding: 1rem;
  }
`;

interface $AccordionTitleProps {
  $color: string;
  $isList?: boolean;
}
export const $AccordionTitle = styled.div<$AccordionTitleProps>`
  color: ${({ $color }) => $color};
  display: flex;
  flex-direction: row;
  ${({ $isList }) => ($isList ? 'width: 100%' : '')}
`;

const side = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const $LeftSideContainer = styled.div<{
  $isList?: boolean;
}>`
  ${side};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  user-select: none;

  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: auto;
  ${({ $isList }) => ($isList ? 'width: 100%' : '')}
`;

export const $RightSideContainer = styled.div`
  ${side}
  >svg {
    margin-left: 1rem;
  }
`;
