import React, { FunctionComponent, ReactNode } from 'react';

import { useIsNewBoEnabled } from '../../../../components/FeatureManager/hooks';
import { FIELD_STATUS, InputStatus } from '../helpers/getFinalFieldState.types';

import { $AsteriskSpan, $LabelStyled, $LabelWrap } from './Label.styles';

interface LabelProps {
  required?: boolean;
  status?: InputStatus;
  isRequired?: boolean;
  isError?: boolean;
  htmlFor?: string;
  postLabel?: ReactNode;
  fullWidth?: boolean;
  hasNoMarginBottom?: boolean;
  isHorizontal?: boolean;
}

export const Label: FunctionComponent<React.PropsWithChildren<LabelProps>> = ({
  children,
  status = null,
  required = false,
  isRequired = false,
  isError = false,
  hasNoMarginBottom = false,
  htmlFor,
  postLabel,
  fullWidth,
  isHorizontal,

  ...rest
}) => {
  const transitionRequired = required || isRequired;
  const isNewUi = useIsNewBoEnabled();

  return (
    <$LabelWrap fullWidth={fullWidth}>
      <$LabelStyled
        isError={isError || status === FIELD_STATUS.ERROR}
        $hasNoMarginBottom={isHorizontal ? true : hasNoMarginBottom}
        htmlFor={htmlFor}
        $isNewUi={isNewUi}
        {...rest}
      >
        {children}
        {transitionRequired && <$AsteriskSpan>*</$AsteriskSpan>}
      </$LabelStyled>
      {postLabel}
    </$LabelWrap>
  );
};

Label.displayName = 'Label';
