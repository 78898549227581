import { lighten, tint } from 'polished';
import { Ref } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';
import {
  DefaultStyledProps,
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { scaleDownToScaleIn } from '../../animations/stateAnimation';
import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';
import { isAnimationEntering, rem } from '../../helpers';
import { styledGetCustomComponentProperty } from '../../helpers/theme';

interface NotificationTheming extends DefaultStyledProps {
  hollow?: boolean;
  notificationType?: NOTIFICATION_TYPES;
}

export const colorBasedOnProps = ({
  theme,
  notificationType,
}: NotificationTheming): string => {
  if (notificationType === NOTIFICATION_TYPES.INFO) {
    return theme.colors.primary;
  }
  if (notificationType === NOTIFICATION_TYPES.SUCCESS) {
    return theme.colors.success;
  }
  if (notificationType === NOTIFICATION_TYPES.ALERT) {
    return theme.colors.alert;
  }
  if (notificationType === NOTIFICATION_TYPES.ERROR) {
    return theme.colors.danger;
  }
  if (notificationType === NOTIFICATION_TYPES.DEFAULT) {
    return theme.colors.mainTextColor;
  }

  return theme.colors.primary;
};

export const colorBasedOnPropsIncludingHollow = ({
  theme,
  notificationType,
  hollow,
}: NotificationTheming): string => {
  if (hollow) {
    return colorBasedOnProps({
      theme,
      notificationType,
      hollow,
    });
  }

  return theme.colors.white;
};

interface NotificationWrapperProps extends NotificationTheming {
  animationState: TransitionStatus;
  animationDuration: number;
  ref: Ref<HTMLDivElement>;
  $isFluid: boolean;
  notificationType?: NOTIFICATION_TYPES;
  $isNewBoEnabled?: boolean;
}

export const $NotificationWrapper = styled.div<NotificationWrapperProps>`
  height: auto;
  overflow: hidden;
  color: ${({ $isNewBoEnabled, theme }) =>
    $isNewBoEnabled
      ? theme.newUI.defaultColors.text
      : colorBasedOnPropsIncludingHollow};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  background-color: ${(props) => {
    const color = colorBasedOnProps(props);

    if (props.$isNewBoEnabled && !props.hollow) {
      return 'transparent';
    }

    if (props.$isNewBoEnabled && props.hollow) {
      return props.theme.newUI.variationColors?.light5;
    }

    if (props.hollow) {
      return tint(0.94, color);
    }

    return color;
  }};
  border-radius: ${({ theme, $isNewBoEnabled }) =>
    $isNewBoEnabled ? theme.newUI.borders.mediumRadius : theme.borders.radius};
  ${scaleDownToScaleIn};
  border: ${({ $isNewBoEnabled, theme, hollow }) =>
    $isNewBoEnabled && !hollow
      ? `1px solid ${theme.newUI.variationColors?.light4}`
      : null};

  max-width: ${({ $isFluid }) => ($isFluid ? null : rem(550))};
  margin: 0 auto;
  display: flex;
  align-items: center;
  min-height: ${styledGetCustomComponentProperty({
    componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
    property: SUPPORTED_PROPERTIES.HEIGHT,
  })};
`;

export const $IconContainer = styled.div<{ $isNewBoEnabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: ${rem(50)};
`;

interface TextContainerProps {
  $hasIcon: boolean;
}

const textPadding = 7;

export const $TextContainer = styled.div<TextContainerProps>`
  flex: 1;
  padding: ${({ $hasIcon }) =>
    $hasIcon ? `${rem(textPadding)} 0 ${rem(textPadding)}` : rem(textPadding)};
  &:last-child {
    padding-right: 1.1rem;
  }
`;

interface NotificationContainerProps {
  $height: number;
  animationState: TransitionStatus;
}

export const $NotificationContainer = styled.div<NotificationContainerProps>`
  height: ${({ $height, animationState }) =>
    isAnimationEntering(animationState) ? rem($height + 14) : 0};
  padding-bottom: ${({ animationState }) =>
    isAnimationEntering(animationState) ? '1rem' : 0};
  ${decelerateTimingFunctionTransition};
  transition-duration: 200ms;
  &:last-child {
    height: ${({ $height, animationState }) =>
      isAnimationEntering(animationState) ? rem($height) : 0};
    padding-bottom: 0;
  }
`;

type CloseContainerProps = NotificationTheming;

export const $CloseContainer = styled.button<CloseContainerProps>`
  min-width: ${rem(24)};
  background: none;
  outline: none;
  border: 0;
  cursor: pointer;
  margin-right: ${rem(10)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition-duration: 200ms;
  ${decelerateTimingFunctionTransition};

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.hollow
        ? colorBasedOnProps(props)
        : lighten(0.15, colorBasedOnProps(props))};
    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
