import React, { FunctionComponent, ReactElement } from 'react';
import { Transition } from 'react-transition-group';

import { useIsNewBoEnabled } from '../../components/FeatureManager/hooks';
import { ExternalPortal } from '../../protons/ExternalPortal/ExternalPortal';

import { MODAL_SIZES } from './Modal.helpers';
import SubModal from './SubModal';

interface ModalProps {
  shouldCloseOnDimmerClick?: boolean;
  animationDuration?: number;
  showCrossButton?: boolean;
  shouldShowPadding?: boolean;
  dataTestId?: string;
  icon?: ReactElement;
  onClose?: () => void;
  isOpen?: boolean;
  size?: MODAL_SIZES;
  isNewUi?: boolean;
}

const Modal: FunctionComponent<React.PropsWithChildren<ModalProps>> = ({
  shouldCloseOnDimmerClick = true,
  size = MODAL_SIZES.MEDIUM,
  animationDuration = 300,
  showCrossButton = true,
  shouldShowPadding = true,
  dataTestId = 'modal',
  children,
  isOpen = false,
  onClose,
  icon,
  isNewUi,
}) => {
  const isNewUiEnabled = useIsNewBoEnabled() || isNewUi;

  return (
    <Transition in={isOpen} timeout={animationDuration} unmountOnExit>
      {(animationState) => (
        <ExternalPortal>
          <SubModal
            shouldCloseOnDimmerClick={shouldCloseOnDimmerClick}
            shouldShowPadding={shouldShowPadding}
            animationDuration={animationDuration}
            showCrossButton={showCrossButton}
            animationState={animationState}
            dataTestId={dataTestId}
            onClose={onClose}
            icon={icon}
            size={size}
            isNewUi={isNewUiEnabled}
          >
            {children}
          </SubModal>
        </ExternalPortal>
      )}
    </Transition>
  );
};

Modal.displayName = 'Modal';

export default Modal;
