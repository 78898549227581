import { useMutation, useQueryClient } from 'react-query';

import { QuoteClientAnswer } from '@savgroup-front-common/types/src/Quotation/QuoteClientAnswer';

import { CommonRepairerService } from '../../../../api/RepairerService';
import { useToasts } from '../../../../molecules/NotificationsProvider';
import { GetQuotationsByFileIdQuery } from '../../hooks/useGetQuotationsByFileIdQuery';

export const useValidateQuotation = ({
  quotationId,
  fileId,
  onAnswerSuccess,
}: {
  quotationId: string;
  fileId: string;
  onAnswerSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();
  const { removeAllNotifications, pushErrors } = useToasts();

  const { mutateAsync: handleValidate, isLoading: isValidateLoading } =
    useMutation([], async () => {
      removeAllNotifications();

      const response = await CommonRepairerService.setQuoteClientAnswerCommand({
        quoteId: quotationId,
        clientAnswer: QuoteClientAnswer.APPROVED,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      if (onAnswerSuccess) {
        onAnswerSuccess();
      }

      await queryClient.invalidateQueries({
        queryKey: [GetQuotationsByFileIdQuery, { fileId }],
      });
      await queryClient.invalidateQueries({
        queryKey: ['getInvoicesQuery', { fileId }],
      });

      return undefined;
    });

  return {
    handleValidate,
    isValidateLoading,
  };
};
