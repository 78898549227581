/* eslint-disable jsx-a11y/control-has-associated-label */
import md5 from 'md5';
import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';

import {
  BUTTON_TYPES,
  NOTIFICATION_TYPES,
  TARGET_LINK,
} from '@savgroup-front-common/constants';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import Bold from '@savgroup-front-common/core/src/atoms/Bold/Bold';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { Link } from '@savgroup-front-common/core/src/atoms/link';
import { QualireparLogo } from '@savgroup-front-common/core/src/atoms/Logo/QualireparLogo/QualireparLogo';
import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';
import {
  $ActionRow,
  $Description,
  AdaptedQuotationNewModel,
  ConfirmQuotationRejectModalContent,
  QuotationPendingCustomerValidationContextProvider,
  SparePartQuotationPendingCustomerValidationLine,
  SparePartQuotationPendingCustomerValidationLineSkeleton,
  useQuotationPendingCustomerValidation,
} from '@savgroup-front-common/core/src/components/Quotation';
import {
  $AmountCell,
  $AmountHeaderCel,
  QuotationLine,
  QuotationLineHead,
} from '@savgroup-front-common/core/src/components/Quotation/QuotationLines';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { formatReverseMoneyToString } from '@savgroup-front-common/core/src/formatters/intl';
import useGetSellerConfigurationById from '@savgroup-front-common/core/src/hooks/useGetSellerConfigurationById';
import EnergySavingsIcon from '@savgroup-front-common/core/src/protons/icons/EnergySavings.icon';
import { RecipientType } from '@savgroup-front-common/types';

import { Selectors as CustomerSelectors } from '../../../../../domains/Customer';
import { useMyAccountTypedSelector } from '../../../../../hooks';

import {
  $BannerContent,
  $BonusLine,
  $CenteredHeading,
} from './AnswerQuotationContent.styles';
import messages from './messages';
import NonOwnerRecipientSparePartQuotationLine from './NonOwnerRecipientSparePartQuotationLine/NonOwnerRecipientSparePartQuotationLine';
import { QuotationTotal } from './QuotationTotal';

interface AnswerQuotationContentProps {
  quotation: AdaptedQuotationNewModel;
  initialIsOpen?: boolean;
  isOpen?: boolean;
  sellerId: string;
  fileId: string;
  onAnswerSuccess?: () => void;
}

const AnswerQuotationContent: FC<AnswerQuotationContentProps> = ({
  quotation,
  sellerId,
  onAnswerSuccess,
  fileId,
}) => {
  const fileSummary = useMyAccountTypedSelector(
    CustomerSelectors.selectCustomerFileSummary,
  );
  const { data: sellerConfiguration } = useGetSellerConfigurationById({
    sellerId,
  });

  const {
    formContext,
    adaptedQuotation,
    handleValidate,
    isValidateLoading,
    optionalSpareParts,
    isConfirmRejectModalOpen,
    isRejectLoading,
    handleRejectButtonClick,
    handleRejectWithReturn,
    handleRejectWithDestruction,
    handleCancelConfirmReject,
    isSelectSparePartConditionLoading,
    handleSelectSparePartQuotationLineConditionChange,
    requiredForRepaidSpareParts,
  } = useQuotationPendingCustomerValidation({
    quotation,
    sellerId,
    onAnswerSuccess,
    fileId,
    holderType: fileSummary.fileProducts[0].holder.holderType,
  });
  const { handleSubmit, formState } = formContext;

  const shouldDisplayServiceHeader =
    (adaptedQuotation.administrativeFees || [])
      .concat(adaptedQuotation.services || [])
      .concat(adaptedQuotation.unknownServices || [])
      .concat(adaptedQuotation.unknownSpareParts || [])
      .concat(adaptedQuotation.discounts || []).length > 0;

  return (
    <>
      <$CenteredHeading>
        <Heading level={5}>
          <SafeFormattedMessageWithoutSpread message={messages.title} />
        </Heading>
      </$CenteredHeading>

      <Card>
        <FormProvider {...formContext}>
          <form onSubmit={handleSubmit(() => handleValidate())}>
            <$Description>{adaptedQuotation.description}</$Description>
            <QuotationPendingCustomerValidationContextProvider
              quotation={adaptedQuotation}
            >
              <div>
                {shouldDisplayServiceHeader && (
                  <>
                    <QuotationLineHead isActive gridTemplateColumns="10fr 2fr">
                      <div>
                        <SafeFormattedMessageWithoutSpread
                          message={messages.service}
                        />
                      </div>
                      <$AmountHeaderCel>
                        <SafeFormattedMessageWithoutSpread
                          message={messages.total}
                        />
                      </$AmountHeaderCel>
                    </QuotationLineHead>
                    {(adaptedQuotation.administrativeFees || []).map((fee) => {
                      return (
                        <QuotationLine
                          key={fee.preTaxUnitPrice.amount.toString()}
                          isActive
                          gridTemplateColumns="10fr 2fr"
                        >
                          <div>{fee.description}</div>
                          <$AmountCell>
                            {formatReverseMoneyToString(
                              fee.recipient?.recipientType ===
                                RecipientType.OWNER
                                ? fee.amountIncludedTax
                                : {
                                    amount: 0,
                                    currency: fee.amountIncludedTax.currency,
                                  },
                            )}
                          </$AmountCell>
                        </QuotationLine>
                      );
                    })}
                    {(adaptedQuotation.services || []).map((service) => {
                      return (
                        <QuotationLine
                          key={service.id}
                          isActive
                          gridTemplateColumns="10fr 2fr"
                        >
                          <div>{service.description}</div>
                          <$AmountCell>
                            {formatReverseMoneyToString(
                              service.recipient?.recipientType ===
                                RecipientType.OWNER
                                ? service.amountIncludedTax
                                : {
                                    amount: 0,
                                    currency:
                                      service.amountIncludedTax.currency,
                                  },
                            )}
                          </$AmountCell>
                        </QuotationLine>
                      );
                    })}
                    {(adaptedQuotation.unknownServices || []).map((service) => {
                      return (
                        <QuotationLine
                          key={md5(
                            formatReverseMoneyToString(
                              service.amountIncludedTax,
                            ),
                          )}
                          isActive
                          gridTemplateColumns="10fr 2fr"
                        >
                          <div>{service.description}</div>
                          <$AmountCell>
                            {formatReverseMoneyToString(
                              service.recipient?.recipientType ===
                                RecipientType.OWNER
                                ? service.amountIncludedTax
                                : {
                                    amount: 0,
                                    currency:
                                      service.amountIncludedTax.currency,
                                  },
                            )}
                          </$AmountCell>
                        </QuotationLine>
                      );
                    })}
                    {(adaptedQuotation.unknownSpareParts || []).map(
                      (sparePart) => {
                        return (
                          <QuotationLine
                            key={md5(
                              formatReverseMoneyToString(
                                sparePart.amountIncludedTax,
                              ),
                            )}
                            isActive
                            gridTemplateColumns="10fr 2fr"
                          >
                            <div>{sparePart.description}</div>
                            <$AmountCell>
                              {formatReverseMoneyToString(
                                sparePart.recipient?.recipientType ===
                                  RecipientType.OWNER
                                  ? sparePart.amountIncludedTax
                                  : {
                                      amount: 0,
                                      currency:
                                        sparePart.amountIncludedTax.currency,
                                    },
                              )}
                            </$AmountCell>
                          </QuotationLine>
                        );
                      },
                    )}
                    {(adaptedQuotation.discounts || []).map((discount) => {
                      return (
                        <QuotationLine
                          key={md5(
                            formatReverseMoneyToString(
                              discount.amountIncludedTax,
                            ),
                          )}
                          isActive
                          gridTemplateColumns="10fr 2fr"
                        >
                          <div>{discount.description}</div>
                          <$AmountCell>
                            {formatReverseMoneyToString(
                              discount.recipient?.recipientType ===
                                RecipientType.OWNER
                                ? discount.amountIncludedTax
                                : {
                                    amount: 0,
                                    currency:
                                      discount.amountIncludedTax.currency,
                                  },
                            )}
                          </$AmountCell>
                        </QuotationLine>
                      );
                    })}
                  </>
                )}

                {!requiredForRepaidSpareParts && (
                  <SparePartQuotationPendingCustomerValidationLineSkeleton
                    shouldShowRecipientColumn={false}
                  />
                )}

                {!optionalSpareParts && (
                  <SparePartQuotationPendingCustomerValidationLineSkeleton
                    shouldShowRecipientColumn={false}
                  />
                )}

                {requiredForRepaidSpareParts && optionalSpareParts && (
                  <>
                    {requiredForRepaidSpareParts.length > 0 && (
                      <>
                        <QuotationLineHead
                          isActive
                          gridTemplateColumns="8fr 2fr 2fr"
                        >
                          <div>
                            <SafeFormattedMessageWithoutSpread
                              message={messages.requiredSpareParts}
                            />
                          </div>
                          <div>
                            <SafeFormattedMessageWithoutSpread
                              message={messages.statusSpareParts}
                            />
                          </div>

                          <$AmountHeaderCel>
                            <SafeFormattedMessageWithoutSpread
                              message={messages.total}
                            />
                          </$AmountHeaderCel>
                        </QuotationLineHead>

                        {requiredForRepaidSpareParts
                          .filter(
                            (sparePart) =>
                              sparePart.recipient?.data === RecipientType.OWNER,
                          )
                          .map((sparePartQuotationLine, index) => {
                            return (
                              <SparePartQuotationPendingCustomerValidationLine
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                shouldShowRecipientColumn={false}
                                onSelectSparePartQuotationLineChange={
                                  handleSelectSparePartQuotationLineConditionChange
                                }
                                sparePartQuotationLine={sparePartQuotationLine}
                                index={index}
                              />
                            );
                          })}

                        {requiredForRepaidSpareParts
                          .filter(
                            (sparePart) =>
                              sparePart.recipient?.data !== RecipientType.OWNER,
                          )
                          .map((sparePartQuotationLine) => {
                            return (
                              <NonOwnerRecipientSparePartQuotationLine
                                key={sparePartQuotationLine.id}
                                sparePartQuotationLine={sparePartQuotationLine}
                              />
                            );
                          })}
                      </>
                    )}

                    {optionalSpareParts.length > 0 && (
                      <>
                        <QuotationLineHead gridTemplateColumns="8fr 2fr 2fr">
                          <th colSpan={4}>
                            <SafeFormattedMessageWithoutSpread
                              message={messages.optionalSpareParts}
                            />
                          </th>
                          <th>
                            <SafeFormattedMessageWithoutSpread
                              message={messages.statusSpareParts}
                            />
                          </th>
                          <$AmountHeaderCel>
                            <SafeFormattedMessageWithoutSpread
                              message={messages.total}
                            />
                          </$AmountHeaderCel>
                        </QuotationLineHead>

                        {optionalSpareParts
                          .filter(
                            (sparePart) =>
                              sparePart.recipient?.data === RecipientType.OWNER,
                          )
                          .map((sparePartQuotationLine, index) => {
                            return (
                              <SparePartQuotationPendingCustomerValidationLine
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                shouldShowRecipientColumn={false}
                                onSelectSparePartQuotationLineChange={
                                  handleSelectSparePartQuotationLineConditionChange
                                }
                                sparePartQuotationLine={sparePartQuotationLine}
                                index={index}
                                preDataTestId="optionalSpareParts"
                              />
                            );
                          })}

                        {optionalSpareParts
                          .filter(
                            (sparePart) =>
                              sparePart.recipient?.data !==
                                RecipientType.OWNER &&
                              sparePart.isSelectedForRepair,
                          )
                          .map((sparePartQuotationLine, index) => {
                            return (
                              <NonOwnerRecipientSparePartQuotationLine
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                sparePartQuotationLine={sparePartQuotationLine}
                              />
                            );
                          })}
                      </>
                    )}
                  </>
                )}
              </div>

              <QuotationTotal fileId={fileId} quotation={adaptedQuotation} />

              {quotation.bonus && !quotation.isBonusRemoved && (
                <$BonusLine data-testid="bonus">
                  <QualireparLogo />
                  <div>
                    <Bold>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.congratulation}
                      />
                    </Bold>
                    <p>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.bonusDidactic}
                      />
                      {sellerConfiguration?.qualiReparUrl && (
                        <Link
                          isExternal
                          href={sellerConfiguration?.qualiReparUrl}
                          target={TARGET_LINK.TARGET_BLANK}
                        >
                          <SafeFormattedMessageWithoutSpread
                            message={messages.learnMore}
                          />
                        </Link>
                      )}
                    </p>
                  </div>
                </$BonusLine>
              )}

              <Banner
                notificationType={NOTIFICATION_TYPES.SUCCESS}
                hasIcon={false}
                isFluid
                hollow
              >
                <$BannerContent>
                  <EnergySavingsIcon size="20px" />
                  <SafeFormattedMessageWithoutSpread
                    message={messages.answerQuotationRecommendUsedPartMessage}
                  />
                </$BannerContent>
              </Banner>
            </QuotationPendingCustomerValidationContextProvider>

            <$ActionRow>
              <Button
                type={BUTTON_TYPES.BUTTON}
                danger
                small
                onClick={() => handleRejectButtonClick()}
                isLoading={isSelectSparePartConditionLoading || isRejectLoading}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.buttonReject}
                />
              </Button>
              <Button
                type={BUTTON_TYPES.SUBMIT}
                success
                small
                isLoading={
                  isSelectSparePartConditionLoading || isValidateLoading
                }
                disabled={!formState.isValid}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.buttonValidate}
                />
              </Button>
            </$ActionRow>
          </form>
        </FormProvider>

        <Modal
          size={MODAL_SIZES.TINY}
          onClose={handleCancelConfirmReject}
          isOpen={isConfirmRejectModalOpen}
        >
          <ConfirmQuotationRejectModalContent
            onClose={handleCancelConfirmReject}
            onRejectWithDestruction={handleRejectWithDestruction}
            onRejectWithReturn={handleRejectWithReturn}
          />
        </Modal>
      </Card>
    </>
  );
};

AnswerQuotationContent.displayName = 'AnswerQuotationContent';

export default AnswerQuotationContent;
