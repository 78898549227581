import {
  CURRENCIES,
  ORDER_REGISTRATION_TYPES,
} from '@savgroup-front-common/constants';

import { AddressInfoDto, CUSTOMER_TYPE } from './AddressInfo';
import { Product, SoldServices } from './Product';

export enum ProductStates {
  UNKNOWN = 'Unknown',
  NEW = 'New',
  USED = 'Used',
  RECONDITIONED = 'Reconditioned',
}

export enum OrderSourceTypes {
  UNKNOWN = 'Unknown',
  AUTOMATIC = 'Automatic',
  MANUAL = 'Manual',
  API = 'Api',
}

export interface SearchOrders {
  customerType?: CUSTOMER_TYPE;
  email: string;
  firstName: string;
  lastName: string;
  marketplace: string;
  orderId: string;
  orderReference: string;
  orderType: string;
  ownerId: string;
  sellerId: string;
  sellerName: string;
  tenantId: string;
  isGenericOrder: boolean;
  productsCount: number;
  purchaseDate: Date;
  storePurchasedAt: Date;
  totalPrice: number;
  totalPriceCurrencyCode: number;
}

export interface Order {
  sellerId: string;
  tenantId: string;
  ownerId?: string;
  email: string;
  orderReference: string;
  purchaseDate: Date;
  marketplace?: string;

  storeId?: string;

  orderId: string;
  sellerName: string;
  totalPrice: number;
  totalPriceCurrencyCode: CURRENCIES;
  lastName: string;
  firstName: string;

  products: Product[];

  storePurchasedAt?: string;
}

export interface OrderProductSummary {
  ownerProductId: string;
  sellerProductId: string;
  modelName: string;
  modelTypeId: string;
  brandName: string;
  state: ProductStates;
  endOfWarranty: string;
  soldWarrantyName: string;
  endOfSoldWarranty: string;
  claimContextId: string;
  claimGroupId: string;
  fileId: string;
  notOpenForClaims: boolean;
  noWarranty: boolean;
  pastEndOfWarranty: string;
  orderLineReference: string;
  modelId: string;
}

export interface OrderSummary {
  orderId: string;
  reference: string;
  purchaseDate: Date;
  isGenericOrder: boolean;
  marketPlace: string;
  seller: string;
  sellerId: string;
  invoiceAvailable: boolean;
  products: OrderProductSummary[];
  orderSourceType: OrderSourceTypes;
  noSolution: boolean;
}

export enum PaymentMethod {
  PAYPAL = 'PayPal',
  CREDIT_CARD = 'CreditCard',
  WIRE_TRANSFER = 'WireTransfer',
  VOUCHER = 'Voucher',
  MIXED = 'Mixed',
  ALMA = 'Alma',
  CETELEM = 'Cetelem',
  GIFT_CARD = 'GiftCard',
  OTHERS = 'Others',
  CREDIT_NOTE = 'CreditNote',
  REFUND_BY_WIRE_TRANSFER = 'RefundByWireTransfert',
  REFUND_ON_PAYMENT_METHOD = 'RefundOnPaymentMethod',
  EXCHANGE_ON_SELLER_STOCK = 'ExchangeOnSellerStock',
  CHECK = 'Check',
  DIGITAL_GIFT_CARD = 'DigitalGiftCard',
  CASH_REGISTER = 'CashRegister',
  LENDER = 'Lender',
  LOYALTY_CARD = 'LoyayltyCard',
  PAYEMENT_SERVICE = 'PaymentService',
}

export interface SearchOrderDetails {
  products: Product[];
  deliveryAddress: AddressInfoDto;
  deliveryDate: string;
  soldWarrantyPurchaseDate: string;
  externalOrderLinkUrl: string;
  noSolution: boolean;
  purchaseStoreId: string;
  orderRegistrationType: typeof ORDER_REGISTRATION_TYPES;
  saleReceiptNumber: string;
  paymentMethod: PaymentMethod;
  tenantId: string;
  orderId: string;
  sellerId: string;
  orderReference: string;
  marketplace: string;
  storePurchasedAt: string;
  sellerName: string;
  purchaseDate: string;
  totalPrice: number;
  totalPriceCurrencyCode: CURRENCIES;
  productsCount: number;
  ownerId: string;
  lastName: string;
  firstName: string;
  email: string;
  soldServices: SoldServices[];
  issueId: string;
  reasonId: string;
}
