import React, { ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import {
  ALLOWED_MIME_TYPES,
  DOCUMENT_TYPES,
  IMAGE_DOCUMENT_TYPES,
  media,
  MIME_TYPES,
} from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';

import { CommonClaimService, CommonWorkflowService } from '../../../api';
import {
  FileUploadHookForm,
  NewDesignFileUploadHookForm,
} from '../../../atoms/Form';
import { FieldMessages } from '../../../atoms/Form/common/helpers/getFinalFieldState.types';
import { toast } from '../../../atoms/Toast';
import { safeFormattedIntlString } from '../../../formatters';
import { useToasts } from '../../../molecules/NotificationsProvider';
import messages from '../messages';

import { downloadFileAdditionalInformationBlob } from './helpers/downloadFileAdditionalInformationBlob';
import { QrCodeImportModal } from './QrCodeImportModal/QrCodeImportModal';

interface AdditionalInformationFileInputProps {
  name: string;
  label: MessageType;
  postLabel: string | ReactNode;
  value: { value: File; progress?: number };
  onChange: () => void;
  onRemove: (file: { value: File; progress?: number }) => void;
  isRequired: boolean;
  errors: FieldMessages;
  isLiveUpload: boolean;
  isNewDesign: boolean;
  isImportByQrCode: boolean;
  additionalInformationId: string;
  dataTestId: string;
  claimIds: string[];
  documentType?: DOCUMENT_TYPES;
}

const AdditionalInformationFileInput: React.FC<
  AdditionalInformationFileInputProps
> = ({
  name,
  label,
  postLabel,
  value,
  onChange,
  onRemove,
  isRequired,
  errors,
  isLiveUpload,
  isNewDesign,
  isImportByQrCode,
  additionalInformationId,
  dataTestId,
  claimIds,
  documentType,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const formContext = useFormContext();
  const { setValue } = formContext;

  const { pushErrors } = useToasts();
  const { fileId } = useParams<{ fileId?: string }>();

  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync: handleUploadFinishClick, isLoading } = useMutation(
    ['syncrhonizeFileAdditionalInformationForm'],
    async () => {
      if (fileId) {
        const response = await CommonWorkflowService.getShortFileInfoQuery({
          fileId,
        });

        if (response.failure) {
          pushErrors(response.errors);

          return;
        }

        const fileProduct = response.value.fileProducts.find(
          (fileProduct) => fileProduct.claimId === claimIds[0],
        );
        const fileAdditionalInformation =
          fileProduct?.fileAdditionalInformation?.find(
            (additionalInformation) =>
              additionalInformation.additionalInformationId ===
              additionalInformationId,
          );

        const blob = await downloadFileAdditionalInformationBlob({
          fileAdditionalInformation,
        });

        if (blob) {
          setValue(name, {
            value: blob,
          });

          toast.success(
            safeFormattedIntlString(messages.fileUploadedSuccessfully),
          );
        }
      } else {
        const response =
          await CommonClaimService.getAdditionalInformationByClaimQuery({
            claimId: claimIds[0],
          });

        if (response.failure) {
          setIsOpen(false);

          return;
        }

        const additionalInformations = response.value?.map(
          (x) => x.productAdditionalInformation,
        );

        const fileAdditionalInformation = additionalInformations?.find(
          (additionalInformation) =>
            additionalInformation.additionalInformationId ===
            additionalInformationId,
        );

        const blob = await downloadFileAdditionalInformationBlob({
          fileAdditionalInformation,
        });

        if (blob) {
          setValue(name, {
            value: blob,
          });

          toast.success(
            safeFormattedIntlString(messages.fileUploadedSuccessfully),
          );
        }
      }

      setIsOpen(false);
    },
  );

  const allowedMimeTypes = Object.values(IMAGE_DOCUMENT_TYPES).includes(
    documentType as DOCUMENT_TYPES,
  )
    ? [MIME_TYPES.JPEG, MIME_TYPES.PNG, MIME_TYPES.IMAGE_HEIC, MIME_TYPES.PDF]
    : ALLOWED_MIME_TYPES;

  if (isNewDesign) {
    return (
      <>
        {isImportByQrCode && !isMobileView && claimIds[0] && (
          <QrCodeImportModal
            isOpen={isOpen}
            additionalInformationId={additionalInformationId}
            claimId={claimIds[0]}
            onUploadFinishClick={handleUploadFinishClick}
            isSubmitLoading={isLoading}
          />
        )}

        <NewDesignFileUploadHookForm
          allowedMimeTypes={allowedMimeTypes}
          hollow
          name={name}
          label={label}
          postLabel={postLabel}
          onSelect={onChange}
          onRemove={onRemove}
          file={value}
          isRequired={isRequired}
          errors={errors}
          isLiveUpload={isLiveUpload}
          isFullWidth
          onImportByQrCodeClick={
            isImportByQrCode && !isMobileView
              ? () => {
                  setIsOpen(true);
                }
              : undefined
          }
          dataTestId={dataTestId}
        />
      </>
    );
  }

  return (
    <>
      {isImportByQrCode && !isMobileView && claimIds[0] && (
        <QrCodeImportModal
          isOpen={isOpen}
          additionalInformationId={additionalInformationId}
          claimId={claimIds[0]}
          onUploadFinishClick={handleUploadFinishClick}
          isSubmitLoading={isLoading}
        />
      )}
      <FileUploadHookForm
        allowedMimeTypes={allowedMimeTypes}
        hollow
        name={name}
        label={label}
        postLabel={postLabel}
        onSelect={onChange}
        onRemove={onRemove}
        file={value}
        isRequired={isRequired}
        errors={errors}
        isLiveUpload={isLiveUpload}
        isFullWidth
        onImportByQrCodeClick={
          isImportByQrCode && !isMobileView
            ? () => {
                setIsOpen(true);
              }
            : undefined
        }
        dataTestId={dataTestId}
      />
    </>
  );
};

AdditionalInformationFileInput.displayName = 'AdditionalInformationFileInput';

export default AdditionalInformationFileInput;
