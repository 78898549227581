import styled from 'styled-components';

export const $Didactic = styled.div<{
  $withBottomMargin?: boolean;
  $isNewBoEnabled?: boolean;
}>`
  display: block;
  color: ${({ theme, $isNewBoEnabled }) =>
    $isNewBoEnabled
      ? theme.newUI.defaultColors.text
      : theme.colors.paragraphTextColor};
  font-style: ${({ $isNewBoEnabled }) => ($isNewBoEnabled ? null : 'italic')};
  margin-bottom: ${({ $withBottomMargin = true }) =>
    $withBottomMargin ? '1rem !important' : null};
  white-space: pre-wrap;
`;
