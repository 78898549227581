import React, {
  FunctionComponent,
  ReactElement,
  Ref,
  useEffect,
  useState,
} from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';

import { useIsNewBoEnabled } from '../../components/FeatureManager/hooks';
import { SUPPORTED_ICON_POSITIONS } from '../../helpers/theme';

import { useHumanLoadingButton } from './Button.hooks';
import { $Count, $InnerButtonStyled } from './Button.styles';
import { Icon } from './Icon';
import LoadingBasedOnProps from './LoadingBasedOnProps';

export interface ButtonComponentProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  isError?: boolean;
  animationDuration?: number;
  disabled?: boolean;
  dataTestId?: string;
  small?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  success?: boolean;
  danger?: boolean;
  alert?: boolean;
  hollow?: boolean;
  rounded?: boolean;
  underline?: boolean;
  fluid?: boolean;
  type: BUTTON_TYPES;
  componentThemeName?: string;
  naked?: boolean;
  tabIndex?: number | undefined;
  isPressed?: boolean;
  icon?: ReactElement;
  position?: SUPPORTED_ICON_POSITIONS;
  isSuffix?: boolean;
  isNewUi?: boolean;
  count?: number;
  isMinimal?: boolean;
}

export interface ButtonProps extends ButtonComponentProps {
  forwardedRef: Ref<HTMLButtonElement>;
}

const Button: FunctionComponent<React.PropsWithChildren<ButtonProps>> = ({
  onClick = () => null,
  isLoading = false,
  isError = false,
  animationDuration = 500,
  children,
  disabled = false,
  dataTestId,
  primary = false,
  secondary = false,
  tertiary = false,
  success = false,
  danger = false,
  alert = false,
  rounded = false,
  hollow = false,
  underline = false,
  fluid = false,
  type = BUTTON_TYPES.SUBMIT,
  forwardedRef,
  naked = false,
  isPressed = false,
  icon,
  position = SUPPORTED_ICON_POSITIONS.LEFT,
  componentThemeName,
  isSuffix,
  onMouseDown,
  onMouseLeave,
  onMouseUp,
  onTouchEnd,
  onTouchStart,
  count = 0,
  isNewUi,
  isMinimal,
  ...rest
}) => {
  const [animationReady, setAnimationReady] = useState(false);
  const [timeoutId, setTimeoutId] = useState<any | null>(null);
  const isHumanLoading = useHumanLoadingButton({ isLoading });
  const isSquareIconButton = Boolean(icon && children === undefined);
  const isNewBoEnabled = useIsNewBoEnabled() || isNewUi;

  const resetShake = () => {
    if (isError) {
      const id = setTimeout(() => {
        if (id !== null) {
          setAnimationReady(false);
          setTimeoutId(null);
        }
      }, animationDuration);

      setTimeoutId(id);
      setAnimationReady(true);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <$InnerButtonStyled
      {...rest}
      position={children ? position : undefined}
      componentThemeName={componentThemeName}
      naked={naked}
      type={type}
      primary={primary}
      secondary={secondary}
      tertiary={tertiary}
      success={success}
      danger={danger}
      alert={alert}
      rounded={rounded}
      hollow={hollow}
      underline={underline}
      fluid={fluid}
      isError={isError}
      data-testid={dataTestId}
      isLoading={isHumanLoading}
      ref={forwardedRef}
      animationDuration={animationDuration}
      startShake={isError && animationReady}
      disabled={isLoading || disabled}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        resetShake();
        if (onClick) {
          onClick(e);
        }
      }}
      $isPressed={isPressed}
      $isSquareIconButton={isSquareIconButton}
      $isSuffix={isSuffix}
      $isIcon={icon !== undefined}
      $isOnlyIcon={!!children && icon !== undefined}
      $isNewBoEnabled={isNewBoEnabled}
      $isMinimal={isMinimal}
    >
      <LoadingBasedOnProps
        disabled={disabled}
        isLoading={isHumanLoading}
        secondary={secondary}
        primary={primary}
        tertiary={tertiary}
        alert={alert}
        danger={danger}
        success={success}
      >
        {icon === undefined && (children || '\u00A0')}
        {icon && (
          <Icon
            icon={icon}
            position={children ? position : undefined}
            componentThemeName={componentThemeName}
            tertiary={tertiary}
            secondary={secondary}
            disabled={disabled}
            $isNewBoEnabled={isNewBoEnabled}
          >
            {children}
          </Icon>
        )}
        {count > 0 && <$Count>{count}</$Count>}
      </LoadingBasedOnProps>
    </$InnerButtonStyled>
  );
};

Button.displayName = 'Button';

export default React.forwardRef<HTMLButtonElement, ButtonComponentProps>(
  (props, ref) => <Button forwardedRef={ref} {...props} />,
);
