import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';

import ChevronDisplay from '../../../atoms/ChevronDisplay';
import { useIsNewBoEnabled } from '../../../components/FeatureManager/hooks';
import { ACCORDION_ROLE } from '../Accordion.types';

import { getColorFromProps } from './AccordionHeader.helpers';
import {
  $AccordionHeader,
  $AccordionTitle,
  $LeftSideContainer,
  $RightSideContainer,
} from './AccordionHeader.styles';

interface AccordionHeaderProps {
  isOpen: boolean;
  isDisabled?: boolean;
  isErrored?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  headerRightChildren?: ReactNode;
  label?: ReactElement;
  role?: ACCORDION_ROLE;
  isChevronOnRightSide?: boolean;
  componentThemeName?: string;
  isListTheme?: boolean;
}

const AccordionHeader: FunctionComponent<
  React.PropsWithChildren<AccordionHeaderProps>
> = ({
  children = undefined,
  isOpen,
  isDisabled = false,
  isErrored = false,
  onClick,
  headerRightChildren = undefined,
  label,
  role = ACCORDION_ROLE.PRIMARY,
  isChevronOnRightSide,
  componentThemeName,
  isListTheme = false,
}) => {
  const theme = useTheme();
  const isMobileView = useMedia(media.maxWidth.sm);
  const isNewBoEnabled = useIsNewBoEnabled();

  const color = getColorFromProps({
    theme,
    isDisabled,
    isActive: isOpen,
    isErrored,
    isNewBoEnabled,
  });

  return (
    <$AccordionHeader
      $isActive={isOpen}
      $isDisabled={isDisabled}
      onClick={onClick ?? (() => undefined)}
      $isErrored={isErrored}
      role="button"
      $role={role}
      $componentThemeName={componentThemeName}
      $isList={isListTheme}
      $isNewBoEnabled={isNewBoEnabled}
    >
      {!!onClick && !isChevronOnRightSide && (
        <ChevronDisplay isOpen={isOpen} color={color} />
      )}
      <$LeftSideContainer $isList={isListTheme}>
        {isMobileView && label}
        <$AccordionTitle $color={color} $isList={isListTheme}>
          {children}
        </$AccordionTitle>
      </$LeftSideContainer>
      <$RightSideContainer>
        {!isMobileView && label}
        {headerRightChildren}
      </$RightSideContainer>
      {!!onClick && isChevronOnRightSide && (
        <ChevronDisplay isOpen={isOpen} color={color} />
      )}
    </$AccordionHeader>
  );
};

AccordionHeader.displayName = 'AccordionHeader';

export default AccordionHeader;
