// Imports
import { normalize } from 'polished';
import { createGlobalStyle, css } from 'styled-components';

// Found using Chrome's Converage tool
// Learn more at https://developers.google.com/web/updates/2017/04/devtools-release-notes#coverage
const semanticUiGlobal = css`
  *,
  :after,
  :before {
    box-sizing: inherit;
  }

  body,
  html,
  #root {
    height: 100%;
  }

  html {
    font-size: 14px;
  }

  body {
    margin: 0;
    overflow-x: hidden;
    min-width: 320px;
    background: #f8f9fa;
    font-size: 14px;
    line-height: 1.4285em;
    color: #4a4a4a;
    font-smoothing: antialiased;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 0;
    font-family: Roboto, Helvetica Neue, Arial, Helvetica, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1.28571429em;
    margin: calc(2rem - 0.14285714em) 0 1rem;
    font-weight: 700;
  }

  h1 {
    min-height: 1rem;
    font-size: 2.35rem;
  }

  h2 {
    font-size: 1.45rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.07142857rem;
  }

  h5 {
    font-size: 1rem;
  }

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child {
    margin-top: 0;
  }

  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0 0 1em;
    line-height: 1.4285em;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  *,
  :after,
  :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
`;

export const ScrollBarGlobalStyle = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.25);
    transition: color 0.2s ease;
  }
`;
export const controlStyle = css`
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-width: 320px;
    background: #fff;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
    font-smoothing: antialiased;
  }

  a {
    text-decoration: unset;
  }

  ::-webkit-selection {
    background-color: #cce2ff;
    color: rgba(0, 0, 0, 0.87);
  }
  ::-moz-selection {
    background-color: #cce2ff;
    color: rgba(0, 0, 0, 0.87);
  }
  ::selection {
    background-color: #cce2ff;
    color: rgba(0, 0, 0, 0.87);
  }
  input::-webkit-selection,
  textarea::-webkit-selection {
    background-color: rgba(100, 100, 100, 0.4);
    color: rgba(0, 0, 0, 0.87);
  }
  input::-moz-selection,
  textarea::-moz-selection {
    background-color: rgba(100, 100, 100, 0.4);
    color: rgba(0, 0, 0, 0.87);
  }
  input::selection,
  textarea::selection {
    background-color: rgba(100, 100, 100, 0.4);
    color: rgba(0, 0, 0, 0.87);
  }
  body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }
  body ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }
  body ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.25);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
  body ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.15);
  }
  body ::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 135, 139, 0.8);
  }
`;

interface GlobalStyleArgs {
  myAccount?: boolean;
  control?: boolean;
  isNewBoEnabled?: boolean;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleArgs>`
  ${normalize()};

  ${semanticUiGlobal};

  ${({ myAccount }) => (myAccount ? ScrollBarGlobalStyle : controlStyle)}
  
  h1,
  h2,
  h3,
  h4,
  h5,
  body {
    font-family: ${({ isNewBoEnabled }) =>
      isNewBoEnabled ? 'Inter' : 'Roboto, Arial, Helvetica, sans-serif'};
  }
  
  body {
      color: ${({ isNewBoEnabled }) => (isNewBoEnabled ? '#000' : '#4a4a4a')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${({ myAccount }) => (myAccount ? 'white' : '#F8F9FB')};
    ${({ control }) => (control ? ScrollBarGlobalStyle : null)}

    ul {
        padding-inline-start: 40px;
    }
  }
  
  input:-webkit-autofill { -webkit-box-shadow: 0 0 0px 1000px #FFF inset; }
  input:-moz-autofill { -moz-box-shadow: 0 0 0px 1000px #FFF inset; }
  input:-o-autofill { -o-box-shadow: 0 0 0px 1000px #FFF inset; }
  input:-khtml-autofill { -khtml-box-shadow: 0 0 0px 1000px #FFF inset; }
`;
